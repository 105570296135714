<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div>
          <b-card-group deck>
            <b-card header-tag="header" footer-tag="footer">
              <template v-slot:header>
                <h6 class="mb-0">Danh sách giao dịch</h6>
              </template>
              <div class="filter-transaction">
                <filterTransaction @handleFilter="handleFilter"></filterTransaction>
              </div>
              <b-table
                table-class="datatable-table"
                hover
                tbody-tr-class="datatable-row"
                thead-tr-class="datatable-row"
                thead-class="datatable-head"
                details-td-class="datatable-cell"
                :items="items"
                :fields="fields"
              >
                <template #cell(status)="data">
                  <span>
                    <span
                      :class="getColorStatus(data.value, 'TRANSACTION')"
                      style="text-transform: lowercase"
                      >{{ data.value }}</span
                    >
                  </span>
                </template>
                <template #cell(detail)="data">
                  <a :href="`${data.value}`" target="_blank">Xem</a>
                </template>
                <template #cell(sync_status)="data">
                  <a href="#/transactions/sync-status" @click="syncStatus(`${data.value}`)">Đồng bộ trạng thái</a>
                </template>
              </b-table>
              <div class="mt-3">
                <b-pagination-nav
                  v-model="currentPage"
                  :link-gen="createLinkPage"
                  :number-of-pages="paginate.totalPage"
                  use-router
                  first-number
                  last-number
                  align="right"
                ></b-pagination-nav>
              </div>
            </b-card>
          </b-card-group>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import RepositoryFactory from "@/core/repositories/repositoryFactory";
import filterTransaction from "@/view/pages/transaction/components/filterSyncStatus.vue";
import Common from "@/core/mixins/common";

const TransactionRepository = RepositoryFactory.get("transactions");
export default {
  mixins: [Common],
  data() {
    return {
      filter: {},
      fields: [
        {
          key: "transaction_id",
          label: "Mã giao dịch",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
        {
          key: "wallet_transaction_id",
          label: "Mã thanh toán",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
        {
          key: "created_at",
          label: "Ngày tạo",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
        {
          key: "updated_at",
          label: "Ngày cập nhật",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
        {
          key: "detail",
          label: "Chi tiết",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
        {
          key: "sync_status",
          label: "Thao tác",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
      ],
      items: [],
      currentPage: 1,
      paginate: {
        limit: 25,
        page: 1,
        totalPage: 1,
        total: 0,
        skip: 0,
      },
    };
  },
  components: {
    filterTransaction,
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Danh sách mã thanh toán lệch trạng thái", route: "sync-status" },
    ]);
    this.filter = this.$route.query;
  },
  created() {
    this.getList();
  },
  methods: {
    async getList() {
      try {
        this.$bus.$emit("show-loading", true);
        let params = this.filter;
        params.page = this.currentPage;
        params.limit = this.paginate.limit;
        let response = await TransactionRepository.getSyncStatus(params);
        this.$bus.$emit("show-loading", false);
        let body = response.data;
        if (body.error_code === 1) {
          alert(body.message);
        } else {
          if (body.data.data) {
            body.data.data.forEach((value) => {
              value["detail"] =
                      "/#/transactions/detail?transaction_id=" + value.transaction_id;
              value["sync_status"] = value.wallet_transaction_id;
            });
            this.items = body.data.data;
            this.currentPage = body.data.current_page;
            this.paginate.total = body.data.total;
            this.paginate.totalPage = body.data.last_page;
          }
        }
      } catch (e) {
        this.$bus.$emit("show-loading", false);
      }
    },
    async syncStatus(data) {
      this.$bus.$emit("show-loading", true);
      let response = await TransactionRepository.syncStatus({wallet_transaction_id: data});
      this.$bus.$emit("show-loading", false);
      let body = response.data;
      if (body.error_code === 0) {
        alert('Đồng bộ thành công');
        this.getList();
      } else {
        alert(body.message);
      }
    },
    handleFilter(filter) {
      this.filter = filter;
      this.getList();
    },
  },
  watch: {
    $route: "getList",
    currentPage() {
      this.getList();
    },
  },
};
</script>
